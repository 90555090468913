<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar-new v-model="appliedFilters" @apply-filter="applyFilter"></filter-side-bar-new>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.task.status.active') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.task.status.inactive')
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="appliedFilters" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <custom-vue-table
                  ref="vuetable"
                  http-method="get"
                  :api-url="processEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="appliedFilters"
                  :detailRowComponent="detail"
                  track-by="Numero_Processo"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a  class="btn btn-link btn-padding" :title="$t('actions.add_info_to_alerts')"
                          v-on:click="addInfoToAlerts(props.rowData)">
                        <i class="text-primary  fa fa-info font-20"></i>
                      </a>

                      <a  v-if="appliedFilters.id_status == 1" class="btn btn-link btn-padding" :title="$t('actions.remove_from_alerts')"
                          v-on:click="removeFromAlerts(props.rowData)">
                        <i class="text-primary  ft-user-check font-20"></i>
                      </a>
                      <a  v-else class="btn btn-link btn-padding" :title="$t('actions.add_to_alerts')"
                          v-on:click="addToAlerts(props.rowData)">
                        <i class="text-primary  ft-user-x font-20"></i>
                      </a>
                      <a  class="btn btn-link btn-padding" :title="$t('actions.log')"
                          v-on:click="showLogs(props.rowData)">
                        <i class="text-primary  ft-file-text font-20"></i>
                      </a>
                      <a v-if="!isOpened(props.rowData)"  class="btn btn-link btn-padding" :title="$t('actions.info_show')"
                         v-on:click="onTogleDetails(props.rowData)">

                        <i class="text-primary  ft-plus-circle font-20"></i>
                      </a>
                      <a v-else class="btn btn-link btn-padding" :title="$t('actions.info_hide')"
                         v-on:click="onTogleDetails(props.rowData)">

                        <i class="text-primary  ft-minus-circle font-20"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FilterSideBarNew from '@/components/FilterSideBarNew'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components
import CustomVueTable from '@/components/Table/CustomVueTable'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

import VModal from 'vue-js-modal'
import UpdateStatus from '../../UpdateStatus'
import AddInfo from '../../AddInfo'
import ShowLogs from '../../ShowLogs'
import Detail from './Detail.vue'

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'ReportAlertsProcessosDivergenciaDebitNote',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.alerts.title') + ' - %s'
    }
  },
  data () {
    return {
      detail: Detail,
      tipoAlerta: '09011',
      isLoading: false,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/report/alert/processos-divergencia-debit-note',
      appliedFilters: {
        id_status: 1,
        internal_code: null,
        unfiltered_customers: [],
        employees: []
      },
      sortOrder: [
        { field: 'Data_Abertura_Processo', direction: 'desc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBarNew,
    Loading,
    FilterContainer
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    isOpened (row) {
      return this.$refs.vuetable.$refs.customVuetable.isVisibleDetailRow(row.Numero_Processo)
    },
    onTogleDetails (row) {
      this.$refs.vuetable.$refs.customVuetable.toggleDetailRow(row.Numero_Processo)
    },
    onCloseModal () {
      this.$refs.vuetable.$refs.customVuetable.refresh()
    },
    // Loader
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.appliedFilters.id_status = idStatus
      this.applyFilter()
    },
    applyFilter () {
      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.process.status.open'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.process.status.awaiting_shipping'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.process.status.boarded'),
          'color': '#c59b6b'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.process.status.landed'),
          'color': '#723886'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.process.status.paided'),
          'color': '#0074a0'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.process.status.finalized'),
          'color': '#5ec65f'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    showLogs (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      this.$modal.show(ShowLogs, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        }
      )
    },
    addInfoToAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      this.$refs.vuetable.$refs.customVuetable.hideDetailRow(rowData.Numero_Processo)

      let me = this
      this.$modal.show(AddInfo, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    addToAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      rowData.situacao = 1
      let me = this
      this.$modal.show(UpdateStatus, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    removeFromAlerts (rowData) {
      rowData.tipoAlerta = this.tipoAlerta
      rowData.situacao = 2
      let me = this
      this.$modal.show(UpdateStatus, { alertData: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'Numero_Processo',
          title: this.$i18n.t('form.process.internal_code'),
          sortField: 'Numero_Processo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Cliente',
          title: this.$i18n.t('form.process.customer_name'),
          sortField: 'Cliente',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Armador',
          title: this.$i18n.t('form.process.armador'),
          sortField: 'Armador',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Responsavel',
          title: this.$i18n.t('report.responsavel'),
          sortField: 'Responsavel',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Data_Abertura_Processo',
          title: this.$i18n.t('form.process.data_abertura'),
          sortField: 'Data_Abertura_Processo',
          formatter: (value) => this.$util.formatDate(value, undefined, ' - ')
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions wrap-actions-large',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    process () {
      return this.$route.params.process || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
  .wrap-actions-large{
    width: 300px;
  }
</style>
